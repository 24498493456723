<template>
  <p class="txt mon-regular">{{ texts.text }}</p>
</template>

<script>
export default {
  name: "EmptyTextComponent",
  props: {
    text: {
      type: Object,
    },
  },
  data() {
    return {
      //variables
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.global[this.selectLanguage];
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.global[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.txt {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}
</style>